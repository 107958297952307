<template>
    <div class="card__settings">
        <div class="calendar__name">
            {{calendarName}}
        </div>
        <div class="settings__content">
           <SlotSettingsFormVue
                :form="form"
            />
        </div>
        <div class="form__footer">
            <div class="template__tools">
                <!-- <div class="manage__template" v-if="templateIsSelected && templateIsChanged">
                    <div class="title" @click="openManageTemplateTools">
                        <div v-if="manageTemplateSelectedToolName">
                            {{ manageTemplateSelectedToolName }}
                        </div>
                        <div v-else>
                            Manage template
                        </div>
                    </div>
                    <div class="tools" v-if="manageTemplateToolsIsOpen">
                        <div class="tool__item" @click="() => selectManageTemplateTool(1, 'Update Template')" v-bind:class="{
                            selected: manageTemplateSelectedTool == 1
                        }">
                            Update Template
                        </div>
                        <div class="tool__item" @click="() => selectManageTemplateTool(2, 'Create New Template')" v-bind:class="{
                            selected: manageTemplateSelectedTool == 2
                        }">
                            Create New Template
                        </div>
                    </div>
                </div> -->
                <b-checkbox v-model="form.isCreateTemplate">Create template</b-checkbox>
            </div>
            <div class="submit_buttons_cont">
                <b-button @click="submitHandler(form)" type="is-info">Save</b-button>
                <b-button @click="closeHandler(form.date)" type="is-info is-light">Cancel</b-button>
            </div>
        </div>
    </div>
</template>

<script>
import YYYYMMDD, { convertTZ, isEqualDate } from '../../../scripts/date';
import { Checkbox, Button} from 'buefy'

import 'air-datepicker/air-datepicker.css';
import CMS from '../../../service/cms/service';
import Slot from '../../../scripts/slot';
import { bus } from '../EventBus.js';

import SlotSettingsFormVue from '../../forms/slot-settings/SlotSettingsForm.vue';
import SlotSettingsFormInstance from '../../forms/slot-settings/formInstance';

export default {
    name: "SlotSettings",
    props: {
        eventSlot: Slot || Object,
        submitHandler: {
            type: Function,
            required: true,
        },
        closeHandler: {
            type: Function,
            required: true,
        },
    },
    components: {
        SlotSettingsFormVue,
        Checkbox,
        Button
    },
    computed: {
        calendarUid(){
            return this.$route.params.calendarUid
        },
        calendarName(){
            return this.$store.getters.calendarTitle
        },
        templates(){
            return this.$store.getters.calendarSlotTemplates
        },
        calendarTimezone(){
            let calendarTimezone = this.$store.getters.getTimezone
            if (calendarTimezone) {
                this.form.calendarTimezone = calendarTimezone
            }
            return calendarTimezone
        },
    },
    data() {
        return {
            form: null,
        }  
    },
    created() {

        if (this.eventSlot){
            this.form = new SlotSettingsFormInstance({
                ...this.eventSlot.settings,
                startAt: this.eventSlot.period.start.date,
                duration: this.eventSlot.period.duration,
                fixedAttenders: this.eventSlot.attenders,
            })
        } else {
            let date = null
            let tableHeader = this.$store.getters.tableHeader
            if (tableHeader)
                date = tableHeader.selectedDate
            this.form = new SlotSettingsFormInstance({
                startAt: date,
                isCreate: true,
            })
            this.form.timeString = ""
        }

		if (!this.templates || this.templates.length == 0){
			this.$store.dispatch('setup-slot-templates')
                .then(this.setupSelectFields)
		} else if (!this.eventSlot) {
            let hosts = this.$store.getters.calendarHosts
            let levels = this.$store.getters.calendarPriceLevels
            let ratings = this.$store.getters.calendarRatings

            this.setupSelectFields({hosts, levels, ratings})
        }
	},
    methods: {
        setupSelectFields(data){
            let {hosts, ratings, levels} = data

            if (!this.eventSlot && hosts && hosts.length > 0) 
                this.form.hostId = hosts[0].id
            if (!this.eventSlot && ratings && ratings.length > 0)                    
                this.form.ratingId = ratings[0].id
            if (!this.eventSlot && levels && levels.length > 0)
                this.form.priceLevelId = levels[0].id
        },
        calendarDateTimeInTZ(initDate){
            let date = convertTZ(initDate, this.calendarTimezone.name, this.timezone.name)

            let calendarTZName = this.calendarTimezone.name.split(' ')[0]
            let result = date.toTimeString().slice(0,5) + ` ${calendarTZName}`

            if (date.getDate() != initDate.getDate()){
                result = date.toLocaleDateString() + " " + result
            }

            this.calendarDate = date

            return result
        },
        openManageTemplateTools(){
            this.manageTemplateToolsIsOpen = !this.manageTemplateToolsIsOpen 
        },
        selectManageTemplateTool(index, name)
        {
            if (this.manageTemplateSelectedTool == -1 || this.manageTemplateSelectedTool != index){
                this.manageTemplateSelectedToolName = name
                this.manageTemplateSelectedTool = index
            }
            else {
                this.manageTemplateSelectedToolName = ""
                this.manageTemplateSelectedTool = -1
            }
            this.openManageTemplateTools()
        },
    },
}
</script>

<style scoped>
.card__settings{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 40px calc(100% - 40px);
}

.calendar__name{
    position: sticky;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 100%;
    font-weight: 600;
    border-bottom: 2px solid rgb(240, 240, 240);
}

.settings__content{
    padding-bottom: 0;
    min-width: 260px;
    height: 100%;
}

.settings__content > * {
    min-width: 260px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}


.settings__content > .main__properties{
    margin-bottom: 16px;
}
.settings__content > .secondary__properties{
    overflow-y: auto;
    height: calc(100% - 182px - 16px);
}

.property.with__title{
    display: grid;
    grid-template-columns: 30% calc(70% - 10px);
    grid-column-gap: 10px;
}
.property.property.with__title > .title{
    display: flex;
    align-items:center;
}

/* Date, time, duration */

.date_duration_cont{
    display: grid;
    grid-template-columns: calc(100% - 16px - 115px) 115px;
    grid-column-gap: 16px;
    grid-row-gap: 7px;
}

.date__cont{
    display: flex;
    flex-direction: row;
    gap:16px;
}

.duration__cont{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 10px;
}

.timezone{
    color: rgb(0, 117, 255);
    text-decoration: underline;
    font-size: 11px;
    cursor: pointer;
}

.current__datetime{
    text-align: right;
    font-size: 11px;
}

.current_week_day{
    text-align: right;
    font-size: 11px;
}

input {
    width: calc(100% - 12px - 2px);
    padding: 6px 6px 7px 6px;
    font-size: 16px;
    border-radius: 2px;
    border: 1px solid rgb(68, 68, 68);
}

textarea{


    font-family: 'Lato', sans-serif;
    font-size: 16px;
    padding: 6px 6px 7px 6px;
    border-radius: 2px;
    border: 1px solid rgb(68, 68, 68);

    height: 19px;
    width: calc(100% - 12px - 2px);
    
    max-height: 70px;
    resize: none;
    overflow-y: auto;
}

input:focus, textarea:focus{
    outline: none;
}

input.disabled{
    background: #e9e9e9;
    color: grey;
}

input[type='checkbox']{
    height: 18px;
    width: 18px;
}


.detail_buttons_cont{
    display: grid;
    font-size: 14px;    
    grid-template-columns: repeat(3, 1fr);
}



.detail__button{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    border: 1px solid black;
    border-right: none;
}

.detail__button:last-child{
    border-right: 1px solid black;
    border-radius: 0 7px 7px 0;
}

.detail__button:first-child{
    border-radius: 7px 0 0 7px;
}

.max__attenders{
    display: grid;
    grid-template-columns: calc(100% - 10px - 106px) 106px;
    grid-column-gap: 10px;
}

.attend__buttons{
    display: grid;
    grid-template-columns: repeat(2, 50%);
    width: 106px;
}

.attend__buttons > div{
    display: flex;
    justify-content: center;
    align-items:center;
    border: 1px solid black;
    border-right: none;
}

.attend__buttons > div:first-child{
    border-radius: 7px 0 0 7px;
}

.attend__buttons > div:last-child{
    border-right: 1px solid black;
    border-radius:  0 7px 7px 0;
}

.wait_list_cont{
    display: grid;
    grid-template-columns: calc(100% - 10px - 106px) 106px;
    grid-column-gap: 10px;
}

.ready_to_attend{
    display: grid;
    grid-template-columns: calc(100% - 10px - 106px) 106px;
    grid-column-gap: 10px;
    margin-bottom: 16px;
}

.manage__template > .title {
    color:#0075ff;
    text-decoration: underline;
}

.manage__template{
    position: relative;
    width: 200px;
}

.manage__template > .tools{
    position: absolute;
    bottom: 0;
    left: 160px;
    width: 175px;

    background: white;
    border: 1px solid;
    border-radius: 6px;
    padding: 10px 0;

    display: flex;
    flex-direction: column;
    gap: 10px;
}

.manage__template > .tools > .tool__item{
    padding: 3px 10px ;
}

.manage__template > .tools > .tool__item.selected{
    background: lightgrey;
}

.submit_buttons_cont{
    padding: 20px;
    padding-top: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 10px;
    background: white;
    height: 40px;
}

.save__button{
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    height: 40px;
    background: #0075ff;
    border-radius: 7px;
    color: white;

    font-size: 20px;
    font-weight: bold;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently */
}

.cancel__button{
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    height: 40px;
    color: #0075ff;
    text-decoration: underline;
}

.date_input_calendar{
    display: grid;
    border: 1px solid rgb(68, 68, 68);
    grid-template-columns: calc(100% - 30px) 30px;
}

.date_input_calendar > input{
    border: none;
}

.date_input_calendar > div{
    border-left: 1px solid rgb(68, 68, 68);
    display: flex;
    justify-content: center;
    align-items: center;
}

.tabs__cont{
    border-radius: 7px;
    display: grid;
    grid-template-columns: repeat(3, auto);
}

.tab__item{
    border: 1px solid #0075ff;
    border-right: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    text-align: center;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently */
}

.tab__item:first-child{
    border-radius: 7px 0 0 7px;
}

.tab__item:last-child{
    border-right:1px solid #0075ff;
    border-radius: 0 7px 7px 0;
}

.tab__item.selected{
    background: #0075ff;
    color: snow;
    font-weight: 500;
}
.attenders__cont{
    height: 100%;
    overflow-y: auto;
}
.fixed__attender{
    
    font-size: 14px;
    display: grid;
    grid-template-columns: calc(100% - 31px - 10px) 31px;
    grid-column-gap: 10px;
}

.fixed__attender > .title{
    padding: 7px 9px;
}

.fixed__attender > .delete__button{
    display: flex;
    justify-content: center;
    align-items: center;
}

.fixed__attender.second{
    background: lightgray;
}


.empty__list{
    display: flex;
    justify-content: center;
    align-items: center;
    color:grey
}

.wallpaper__cont{
    display: flex;
    height: fit-content;
    flex-wrap: wrap;
    gap: 10px;
}

.wallpaper__item{
    height: 160px;
    width: 90px;
    position: relative;
    color: #0075ff;
}

.wallpaper__item > .selector{
    position: absolute;
    bottom: 7px;
    right: 7px;
    width: fit-content;
}

.wallpaper__item > img{
    pointer-events: none;
}

.wallpaper_item_tools{
    position: absolute;
    top: 0;
    z-index: 4;
    width: inherit;
    height: inherit;
}

.wallpaper_item_tools > .wrapper{
    display: grid;
    grid-template-rows: repeat(2, 50%);
    width: inherit;
    height: inherit;
    user-select: none;
}

.wallpaper_item_tools > .wrapper > .tool{
    display: flex;
    justify-content: center;
    align-items: center;
}

.add__wallpaper{
    border: 3px dashed #9a9aa8;
    color: #9a9aa8;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 154px;
    width: 84px;
}

.buttons__cont{
    margin-top: 10px;
    width: 210px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 10px;
}

.buttons__cont > * {
    display: flex;
    justify-content:center;
    align-items: center;
    padding: 4px 7px;
        -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently */
}

.buttons__cont > .save{
    border-radius: 7px;
    background: lightgray;
}

.buttons__cont > .cancel{
    text-decoration: underline;
    color: #0075ff;
}

.add__button{
    background: lightgray;
    border-radius: 5px;
    padding: 4px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    user-select: none;
}

.new_attender_field{
    padding: 6px 8px;
    border-radius: 7px;
}

.add_fixed_attender{
    margin-top:10px
}

.color__picker{
    background: white;
    padding: 20px;
    display: grid;
    grid-template-rows: auto;
    grid-row-gap: 16px;
}

.upload__backgorund{
    background: white;
    min-height: 100%;
}

.upload__backgorund > .title{
    font-size: 20px;
    text-align: center;
    padding: 10px;
}

.form__footer{
    position: fixed;
    bottom: 0;
    right: 0;

    display: flex;
    gap: 16px;
    flex-direction: column;

    width: 100%;
    background: white;
    border-top: 1px solid lightgrey;
    padding-bottom: 16px;
    z-index: 5;
}

.template__tools{
    padding: 10px 24px;
    height: 40px;
}

.choose_template_cont{
    display: flex;
    justify-content: flex-end;
}   
</style>